import Academy from '../pages/Academy/Academy';
import Group from '../pages/Group/Group';
import Record from '../pages/Record/Record';
import Reports from '../pages/Reports/Reports';
import HealthCheck from '../pages/Users/HealthCheck/HealthCheck';
import * as paths from './paths';

export default [
    {
        id: '1',
        label: 'Usuarios',
        icon: 'ri-user-3',
        children: [
            { 
                id: '1.1',
                label: 'Grupos',
                path: '/grupos',
                icon: 'ri-group-2-fill',
                component: Group
            },
            { 
                id: '1.2',
                label: 'Health check',
                path: `${paths.USERS}${paths.HEALTH_CHECK}`,
                icon: 'ri-draft-line',
                component: HealthCheck,
            },
        ],
    },
    {
        id: '2',
        label: 'Reportes',
        component: Reports,
        path: '/reportes',
        icon: 'ri-layout-masonry'
    },
    {
        id: '3',
        label: 'Academy',
        icon: 'ri-graduation-cap',
        children: [
            { 
                id: '3.1',
                label: 'Playlist',
                path: '/playlist',
                icon: 'ri-play-list-2-fill',
                component: Academy
            },
            { 
                id: '3.2',
                label: 'Contenido',
                path: '/contenido',
                icon: 'ri-play-circle-line',
                component: Academy
            },
        ],
    },
    {
        id: '4',
        label: 'Expediente',
        icon: 'ri-article',
        children: [
            { 
                id: '4.1',
                label: 'Alumnos',
                path: '/alumnos',
                icon: 'ri-draft-line',
                component: Record
            }
        ],
    },
   
];