import React from 'react';

// Components
import FormGroup from '../FormGroup/FormGroup';

// Shared components
import Toast from '../../../design-system/components/Toast/Toast';
import DrawerContainer from '../../../design-system/components/Drawer/DrawerContainer';

const EdithGroup = ({ loading, title, open, classes, onChangeLoadingDrawer, onChangeDrawer, tagGroupModuleId, onReloadTable, isDuplicatingGroup }) => {
    // STATES
    const [alertProps, setAlertProps] = React.useState({
        open: false,
        severity: "success",
        duration: 4000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        message: "",
    });

    // HANDLES
    const hanldeFinishedToast = () => {
        setAlertProps(prevState => ({ ...prevState, open: false, message: "", }));
    };
    
    const handleChangeDrawer = (data) => {
        const isDataAnObject = typeof data === "object";
        const changeDrawerResponse = !!isDataAnObject ? !open : data;
        onChangeDrawer(changeDrawerResponse);
    };

    return (
        <>
            <DrawerContainer
                loading={loading}
                title={title}
                open={open}
                handleClickOpen={handleChangeDrawer}
                classes={classes}>
                <FormGroup onChangeDrawer={handleChangeDrawer} onChangeLoadingDrawer={onChangeLoadingDrawer} tagGroupModuleId={tagGroupModuleId} onReloadTable={onReloadTable} isDuplicatingGroup={isDuplicatingGroup} />
            </DrawerContainer>

            <Toast {...alertProps}
                onFinished={hanldeFinishedToast}
            />
        </>
    );
};

export default EdithGroup;